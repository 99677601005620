'use client';

import { ErrorPage } from '@exploration/pages-error';
import { FallbackPageLayout } from '@exploration/pages-shared';
import { logger } from '@thrivent-web/logging-utils';
import { useEffect } from 'react';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    logger.error('Error caught at boundary', error, {
      digest: error.digest,
    });
  }, [error]);

  return (
    <FallbackPageLayout>
      <ErrorPage title="Oops! Sorry, we encountered an error." />
    </FallbackPageLayout>
  );
}

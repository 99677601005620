import BasicWhiteBackgroundFooter from '../footers/BasicWhiteBackgroundFooter';
import { BasicWhiteBackgroundHeader } from '../headers/BasicWhiteBackgroundHeader';
import { LandmarkLink } from '@exploration/shared-components';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const FallbackPageLayout = ({ children }: Props) => (
  <>
    <BasicWhiteBackgroundHeader />

    <LandmarkLink id="content" href="#content">
      Start of main content
    </LandmarkLink>

    {children}

    <BasicWhiteBackgroundFooter />
  </>
);
